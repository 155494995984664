.map-canvas {
  position: static !important;
}
.no-scroll {
    --overflow: hidden;
}
.ionMenuButton {
  --color: var(--ion-color-step-100);
  --background: var(--ion-color-step-900);
}
