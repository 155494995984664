.selectedInstall {
  height: 45px !important;
  width: 41px !important;
  border: 5px solid var(--ion-color-medium);
  border-radius: 50%;
}
.hoveredInstall {
  height: 45px !important;
  width: 41px !important;
  border: 5px solid var(--ion-color-step-900);
  border-radius: 50%;
}

.mydiv {
  height: 150px;
  width: 250px;
}


.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently */
}

.draggable {
  cursor: grab;
}


.whiteBackground {
  background: white;
}
.accordion {
  opacity: 0;
  height: 0;
  margin: 0;
}
.visible {
  opacity: 100;
  overflow-y: auto;
}

.paddedColumn {
  --ion-grid-column-padding: 10px;
}

.installHover {
  --background-hover: var(--ion-color-step-300);
  --color-hover: var(--ion-color-step-900);
}
.highlightedInstall {
  --background: var(--ion-color-step-300);
  --color: var(--ion-color-step-900);
}

.markerColorRed {
  background: #e04a63;
  color: white;
  --background: #e04a63;
  --color: white;
}
.markerColorBlue {
  background: #268bd2;
  color: white;
  --background: #268bd2;
  --color: white;
}
.markerColorGreen {
  background: #2ce63e;
  color: white;
  --background: #2ce63e;
  --color: white;
}
.markerColorOrange {
  background: #e6922c;
  color: white;
  --background: #e6922c;
  --color: white;
}
.markerColorPurple {
  background: #832ce6;
  color: white;
  --background: #832ce6;
  --color: white;
}
.markerColorPink {
  background: #e62cc4;
  color: white;
  --background: #e62cc4;
  --color: white;
}
.markerColorAqua {
  background: #2ce6cd;
  color: white;
  --background: #2ce6cd;
  --color: white;
}
.markerColorYellow {
  background: #e6e62c;
  color: white;
  --background: #e6e62c;
  --color: white;
}
