.text1 {
  font-size: 1em;
}
.text2 {
  font-size: .8em;
  border-top: 1px solid var(--ion-color-step-500);
  border-bottom: 1px solid var(--ion-color-step-500);
  background: var(--ion-color-step-500);
  color: var(--ion-color-step-100)
}
